/*------------------------------------------------------------------------------
* BACKGROUND COLOR PALETTE =====================================================
------------------------------------------------------------------------------*/

/*----------------------
base background and text
----------------------*/

/* background and drawer */
body.app-body,
.ui,
.drawer__tab,
.drawer__inner,
.drawer__inner.darker,
.drawer__inner__mastodon,
.tabs-bar,
.getting-started,
.search-results__section h5,
.account__section-headline,
    .account__section-headline button,
.notification__filter-bar,
    .notification__filter-bar button,
.timeline-hint,
.introduction,
.account__header__image,
.account__header__account-note textarea:focus,
.flex-spacer, .getting-started, .getting-started__wrapper,
/* DMs */
.status.status-direct,
    .status.status-direct:not(.read),
    .focusable:focus .status.status-direct,
    .status-direct .status__content,
    .notification-favourite .status.status-direct,
/* column preferences */
.column-settings__section,
    .column-header__collapsible,
    .column-header__collapsible-inner,
    .column-header__button.active,
    .setting-meta__label,
    .setting-toggle__label,
.column-subheading,
.content-wrapper,
.media-spoiler,
    .video-player__spoiler,
    .video-player__spoiler.active:active,
    .video-player__spoiler.active:focus,
.react-toggle-track,
.filter-form,
/* in reply to */
.reply-indicator,
.reply-indicator__display-name,
.reply-indicator__content,
.reply-indicator__cancel .icon-button.inverted,
.reply-indicator__content .status__content__spoiler-link,
/* cw show more */
.status__content .status__content__spoiler-link,
.compose__action-bar .icon-button,
/* settings page */
.admin-wrapper .sidebar-wrapper,
    .admin-wrapper .sidebar-wrapper__inner,
    .admin-wrapper .sidebar ul a,
    .admin-wrapper .sidebar ul a.selected,
    .admin-wrapper .sidebar ul ul a,
    .admin-wrapper .content h2,
    .admin-wrapper .content h6,
/* modals */
.boost-modal__action-bar,
    .confirmation-modal__action-bar,
    .mute-modal__action-bar,
.confirmation-modal__action-bar .confirmation-modal__cancel-button,
    .confirmation-modal__action-bar .mute-modal__cancel-button,
    .mute-modal__action-bar .confirmation-modal__cancel-button,
    .mute-modal__action-bar .mute-modal__cancel-button,
.error-column,
    .regeneration-indicator,
    .empty-column-indicator,
.report-modal__comment .setting-text,
.introduction__text p code,
.list-editor .search__input,
.announcements__item,
/* opengraph previews */
.status-card__image,
.status-card__content,
.status-card__description,
.button:disabled,
/* public pages */
body.theme-default,
.button.button-alternative,
    .button.button-alternative-2,
.landing-page__call-to-action,
.public-layout .header,
.public-layout .header .nav-link,
.public-layout .header .nav-button,
.nothing-here,
.brand__tagline,
.table-of-contents
{
background: var(--bgPage);
color: var(--textPage)
}

.tabs-bar__wrapper
{
background: transparent;
color: var(--textPage)
}


/*--------------------
override for bold text
--------------------*/

/* strong elements */
.navigation-bar strong,
.status-card__title,
.status-direct .display-name strong,
.reply-indicator__display-name strong,
.admin-wrapper .content>p strong,
.simple_form strong,
.regeneration-indicator__label strong,
.account__section-headline a.active,
    .account__section-headline button.active,
    .notification__filter-bar a.active,
    .notification__filter-bar button.active,
/* public pages */
.information-board__section,
    .information-board__section span:last-child,
.endorsements-widget .display-name__html,
    .endorsements-widget h4,
.pagination .page,
    .pagination .gap,
    .pagination .newer,
    .pagination .older,
    .pagination a
{
color: var(--textPageBold)
}

/*---------------------
override for muted text
---------------------*/

/* de-emphasized elements */
.navigation-bar,
.navigation-panel hr,
.getting-started,
    .getting-started p,
    .getting-started__footer p,
.column-subheading,
.account__section-headline a,
.status-direct .icon-button,
    .status-direct .display-name,
    .status-direct .status__relative-time,
    .status-direct .status__action-bar__counter__label,
.status-direct.muted .status__content p,
    .status-direct.muted .status__content a,
    .status-direct.muted .display-name strong,
.notification-favourite .status.status-direct .icon-button.disabled,
.simple_form p.hint,
    .simple_form span.hint,
    .admin-wrapper .content .muted-hint,
    .admin-wrapper .content>p,
.status-card__host,
.button:disabled,
.loading-indicator,
.list-editor .search__input::placeholder,
    .list-editor .search__icon .fa,
    .list-editor .search__icon .fa-times-circle,
/* settings page */
body .neutral-hint, .admin-wrapper .content .neutral-hint,
/* public pages */
.endorsements-widget .display-name__account,
.public-layout .footer h4,
    .public-layout .footer ul a,
    .public-layout .footer ul li,
    .public-layout .footer .grid .column-2 h4 a,
    .public-layout .header .nav-button,
/* log in, sign up, forgot passwd */
.form-footer a,
.lighter .simple_form p.hint.subtle-hint
{
color: var(--textPageMuted)
}

/* border color fix */
.status.status-direct:not(.read),
.table-of-contents li a
{border-color: var(--textPageMuted)}
