/*------------------------------------------------------------------------------
* RECOLOR STATUS ACTIONS
*
* This tweak gets its own section because it's pretty messy and long.
*
* Replies, follows, faves, dropdown menu, and share can easily be themed,
* but I can't figure out how to make them look good against the palette.
* Manual color selection may be required, if these colors don't fit well.
*
* Recoloring boosts is another nightmare altogether, because of improper
* SVG embedding in the background-image rather than directly in HTML.
* This leads to two options:
*
* 1) attempt to use filter() to manually add sepia tones and hue-shift
*    - complicated and imprecise adjustments of filter()
*    - cannot use CSS variables in url()s (as in background-image)
*    + however, it does preserve the SVG boosting animation
*
* 2) replace the background-image with a mask-image
*    + less complicated; override background-image with color
*    + can apply CSS variable colors from palette easily
*    - requires extremely long rule to add mask-image
*    - breaks boosting animation
*
* I have chosen option 2.
*
* EDIT: Option 1 is now better because of the new boost icons
* introduced in 3.2.0 -- there is no longer just one icon.
------------------------------------------------------------------------------*/

/* add shadow on hover and active states */
.status__action-bar .icon-button:hover,
    .status__action-bar .icon-button:active,
    .status__action-bar .icon-button.active,
.detailed-status__action-bar .icon-button:hover,
    .detailed-status__action-bar .icon-button:active,
    .detailed-status__action-bar .icon-button.active
{filter: drop-shadow(0px 1px 0px rgba(0,0,0,0.6))}

/* remove bg color on hover and active states*/
.icon-button:active, .icon-button:focus, .icon-button:hover
{background-color: transparent}

/* replies and follows */
.status__action-bar-button.icon-button:nth-child(1):hover,
.status__action-bar-button.icon-button:nth-child(1):active,
.status__action-bar-button.icon-button:nth-child(1).active,
.status__action-bar-button.icon-button:nth-child(1):focus,
.detailed-status__action-bar .detailed-status__button:nth-child(1) .icon-button:hover,
.detailed-status__action-bar .detailed-status__button:nth-child(1) .icon-button:active,
.detailed-status__action-bar .detailed-status__button:nth-child(1) .icon-button.active,
.detailed-status__action-bar .detailed-status__button:nth-child(1) .icon-button:focus,
.notification__message .fa.fa-user-plus,
.account .icon-button:active,
.account .icon-button.active,
.account .icon-button:focus,
.account .icon-button:hover
{color: #0bf;}

/* favourites */
.status__action-bar-button.icon-button:nth-child(3):hover,
.status__action-bar-button.icon-button:nth-child(3):active,
.status__action-bar-button.icon-button:nth-child(3).active,
.status__action-bar-button.icon-button:nth-child(3):focus,
.detailed-status__action-bar .detailed-status__button:nth-child(3) .icon-button:hover,
.detailed-status__action-bar .detailed-status__button:nth-child(3) .icon-button:active,
.detailed-status__action-bar .detailed-status__button:nth-child(3) .icon-button.active,

.notification__favourite-icon-wrapper .star-icon
{color: #f90;}

/* menu */
.status__action-bar-dropdown .icon-button:hover,
.status__action-bar-dropdown .icon-button:active,
.status__action-bar-dropdown .icon-button.active,
.status__action-bar-dropdown .icon-button.focus,
.detailed-status__action-bar-dropdown .icon-button:hover,
.detailed-status__action-bar-dropdown .icon-button:active,
.detailed-status__action-bar-dropdown .icon-button.active,
.detailed-status__action-bar-dropdown .icon-button:focus
{color: #90f;}

/* share */
.icon-button:hover .fa-share-alt
{color: #f09;}

/* boosts */
.notification__message .fa.fa-retweet,
.icon-button:hover .fa-retweet
{color: #0d9;}

button.icon-button:hover i.fa-retweet,
.no-reduce-motion button.icon-button.active i.fa-retweet,
{
    filter: sepia(100%)
        hue-rotate(110deg)
        saturate(700%)
        brightness(100%)
        drop-shadow(0px 1px 0px rgba(0,0,0,0.6))
}
