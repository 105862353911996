/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
p, code,
a, abbr, acronym, address, big,
dfn, img, ins, kbd, s, samp,
small, sub, sup, tt, var,
u, ul, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

small {
  font-size: 0%;
}

smaller {
    font-size: 75%;
    opacity: 0.1;
}

smaller:hover, smaller:active, smaller:focus {
    font-size: 75%;
    opacity: 0.7;
}

.compose-form__modifiers {
    padding: 0em 0.2em;
}

math {
    font-family: symbol;
    font-size:larger;
    display: inline-block;
    padding: 0px;
    margin: 0px;
    vertical-align: baseline;
    line-height: 1.0;
}

math[display="block"] {
    display: block;
    text-align: center;
    page-break-inside: avoid;
}

math > * {
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  border-left: 3px solid #ccc;
  margin: 1.5em 10px;
  padding: 0px 10px;
}

blockquote:before, q:before {
  color: #ccc;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.compose-form__modifiers {
    a {
        color: $highlight-text-color;
	text-decoration: underline;

	&:hover,
	&:focus,
	&:active {
	    text-decoration: none;
	}
    }
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scrollbar-color: lighten($ui-base-color, 4%) rgba($base-overlay-background, 0.1);
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: lighten($ui-base-color, 4%);
  border: 0px none $base-border-color;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: lighten($ui-base-color, 6%);
}

::-webkit-scrollbar-thumb:active {
  background: lighten($ui-base-color, 4%);
}

::-webkit-scrollbar-track {
  border: 0px none $base-border-color;
  border-radius: 0;
  background: rgba($base-overlay-background, 0.1);
}

::-webkit-scrollbar-track:hover {
  background: $ui-base-color;
}

::-webkit-scrollbar-track:active {
  background: $ui-base-color;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
